import { Button, Input, Form, Drawer, ColorPicker, message } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";

import endpoints from "../../utils/endpoints.js";

export default function Update({ data, open, close, sponsorsType }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.room.update, {
        data: values,
      })
      .then((res) => {
        close();
        setIsButtonLoading(false);
        form.resetFields();
        message.success("Sala foi editada com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  function handleWarningMissingFields() {
    message.error("Faltam alguns campos para preencher!");
  }

  return (
    <Drawer
      width={800}
      title={`Editar sala`}
      open={open}
      onClose={close}
      maskClosable={false}
      extra={[
        <Button className="mr-2" size="large" type="primary" onClick={form.submit} loading={isButtonLoading}>
          Guardar
        </Button>,
        <Button disabled={isButtonLoading} size="large" onClick={close}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} id="create-room" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>

        <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Input size="large" placeholder="Nome..." />
        </Form.Item>
        <Form.Item
          name="color"
          label="Cor"
          getValueFromEvent={(color) => {
            return "#" + color.toHex();
          }}
        >
          <ColorPicker size="large" />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
