import { Row, Col, Button, Input, Form, notification, message } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";

import "react-quill/dist/quill.snow.css";
import endpoints from "../../utils/endpoints.js";

function SettingsVersion({ data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (data.length > 0) {
      let versionsData = data.filter((item) => item.name === "version")[0];
      form.setFieldsValue(JSON.parse(versionsData.json));
    }
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.settings.update, {
        data: { values: values, name: "version" },
      })
      .then((resData) => {
        setIsButtonLoading(false);
        message.success("Definições foram editadas com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  function handleWarningMissingFields() {
    message.error("Faltam alguns campos para preencher!");
  }

  return (
    <div className="dashboard-page">
      <Row className="dashboard-content-row">
        <Col span={24}>
          <Form form={form} id="add-commission_type-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
            <Row>
              <Col span={24}>
                <Form.Item hidden name="id">
                  <Input />
                </Form.Item>
                <Form.Item label="Versão iOS" name={"iOS"}>
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Versão Android" name={"android"}>
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button type="primary" size="large" onClick={form.submit} loading={isButtonLoading}>
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default SettingsVersion;
