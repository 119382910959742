import { Row, Col, Button, notification, Divider, Pagination, Spin, Empty } from "antd";
import { EditOutlined, DeleteOutlined, SendOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";

import Create from "../components/Notification/Create";
import Update from "../components/Notification/Update";
import Delete from "../components/Notification/Delete";

import endpoints from "../utils/endpoints";

import CustomTable from "../components/Table";

function Faq() {
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.faq.read)
      .then((res) => {
        let auxData = [];
        for (let i = 0; i < res.data.length; i++) {
          let arrayData = res.data[i];
          let aux = {
            key: i + 1,
            id: arrayData.id,
            name: arrayData.name,
            email: arrayData.email,
            question: arrayData.question,
          };

          auxData.push(aux);
        }

        setTableData(auxData);
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  return (
    <div className="dashboard-page">
      <Row className="dashboard-title-row">
        <Col span={12} className="flex justify-start items-center">
          <h3 className="dashboard-title">Questões</h3>
        </Col>
        <Col span={12} className="flex justify-end items-center">
          <Button className="flex justify-center items-center mr-2" size="large" onClick={handleGetData}>
            <ReloadOutlined />
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard-content-row">
          <Col xs="24" xl={24}>
            <CustomTable
              dataSource={tableData}
              columns={[
                { title: "Nome", dataIndex: "name", key: "name" },
                { title: "E-mail", dataIndex: "email", key: "email" },
                { title: "Questão", dataIndex: "question", key: "question" },
              ]}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Faq;
