import React, { useState, useEffect } from "react";
import { Button, Form, Drawer, Input } from "antd";
import axios from "axios";

import endpoints from "../../utils/endpoints";

function Update({ open, close, data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      form.setFieldsValue(data);
    }
  }, [data]);

  function handleClose() {
    form.resetFields();
    close();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios
      .post(endpoints.user.updateBO, {
        data: values,
      })
      .then((res) => {
        console.log(res);
        setIsButtonLoading(false);
        handleClose();
        form.resetFields();
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-user`}
      title="Atualizar utilizador"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[
        <Button size="large" disabled={isButtonLoading} className="mr-2" onClick={handleClose}>
          Cancelar
        </Button>,
        <Button size="large" loading={isButtonLoading} type="primary" onClick={form.submit}>
          Atualizar
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Nome" size="large" />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Nome" size="large" />
        </Form.Item>
        <Form.Item label="Nova password" name="new_password">
          <Input.Password placeholder="Nova password" size="large" />
        </Form.Item>
        <Form.Item label="Profissão" name="job_title">
          <Input placeholder="Profissão" size="large" />
        </Form.Item>
        <Form.Item label="Instituição" name="institution">
          <Input placeholder="Instituição" size="large" />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Update;
