import { Button, Input, Form, Drawer, ColorPicker, message } from "antd";
import { useState } from "react";
import axios from "axios";

import endpoints from "../../utils/endpoints.js";

export default function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.room.create, {
        data: values,
      })
      .then((res) => {
        close();
        setIsButtonLoading(false);
        form.resetFields();
        message.success("Sala foi adicionada com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  function handleWarningMissingFields() {
    message.error("Faltam alguns campos para preencher!");
  }

  function handleClose() {
    form.resetFields();
    close();
  }

  return (
    <Drawer
      width={800}
      title={`Adicionar sala`}
      open={open}
      onClose={handleClose}
      maskClosable={false}
      extra={[
        <Button className="mr-2" size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
          Adicionar
        </Button>,
        <Button size="large" onClick={handleClose}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} id="create-room" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Input size="large" placeholder="Nome..." />
        </Form.Item>
        <Form.Item
          name="color"
          label="Cor"
          getValueFromEvent={(color) => {
            return "#" + color.toHex();
          }}
        >
          <ColorPicker format="hex" size="large" />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
