import { Row, Col, Button, Input, TimePicker, Select, Radio, Form, notification, Drawer, Upload, message } from "antd";
import { useState } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import config from "../../utils/config.js";
import endpoints from "../../utils/endpoints.js";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;
const { Option } = Select;
const { RangePicker } = TimePicker;

function DrawerAddCommission({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState("");

  const [form] = Form.useForm();

  const uploadProps = {
    accept: "image/png, image/jpeg",
    name: "file",
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview("");
    },
    beforeUpload: (file) => {
      handlePreview(file, "image");
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  function handleSubmit(values) {
    setIsButtonLoading(true);
    let formData = new FormData();

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("file", file);
      });
    } else {
      values.img = null;
    }

    formData.append("data", JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.committee.create}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((resData) => {
        handleCloseDrawer();
        setIsButtonLoading(false);
        form.resetFields();
        message.success("Membro da comissão foi adicionado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  function handleWarningMissingFields() {
    message.error("Faltam alguns campos para preencher!");
  }

  function handleCloseDrawer() {
    close();
    setFileList([]);
    setFilePreview("");
    form.resetFields();
  }

  async function handlePreview(file, type) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Drawer
      width={800}
      className="drawer-antd-committee"
      title={`Adicionar membro`}
      open={open}
      onClose={handleCloseDrawer}
      maskClosable={false}
      extra={[
        <Button className="mr-2" size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
          Adicionar
        </Button>,
        <Button size="large" onClick={close}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} id="add-moderator-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item name="type" label="Tipo" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
              <Select
                showSearch
                allowClear
                size="large"
                placeholder="Tipo de comissão..."
                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                options={["Steering", "Scientific"].map((value, index) => ({
                  label: value,
                  value: value,
                }))}
              />
            </Form.Item>
            <Form.Item name="img" label="Imagem" valuePropName="fileList" getValueFromEvent={normFile}>
              <Dragger {...uploadProps} className={`dragger ${filePreview ? "has-preview" : ""}`}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <div
                  className={`preview-file sponsor ${filePreview ? "has-preview" : ""}`}
                  style={{
                    backgroundImage: `url(${filePreview && filePreview.includes("base64") ? filePreview : `${config.server_ip}media/${filePreview}`})`,
                  }}
                ></div>
              </Dragger>
            </Form.Item>
            <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
              <Input size="large" placeholder="Nome..." />
            </Form.Item>
            <Form.Item name="work" label="Local de trabalho/Afiliação">
              <Input size="large" placeholder="Local de trabalho/Afiliação..." />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default DrawerAddCommission;
