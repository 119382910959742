import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import { Navigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className="dashboard_error">
      <h1>404</h1>
      <p>
        Página não encontrada!
        <br />
        Por favor verifique o seu URL.
      </p>
      <Button size="large" type="primary" onClick={() => navigate("/")}>
        Dashboard
      </Button>
    </div>
  );
}

export default PageNotFound;
