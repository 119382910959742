import { Row, Col, Button, Input, Radio, Form, notification, Drawer, Upload, Select, InputNumber, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useState } from "react";
import "react-quill/dist/quill.snow.css";
import config from "../../utils/config.js";
import endpoints from "../../utils/endpoints.js";

const { Dragger } = Upload;

export default function Create({ open, close, sponsorsTiers }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState("");

  const [form] = Form.useForm();

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadProps = {
    name: "file",
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview("");
    },
    beforeUpload: (file) => {
      handlePreview(file, "image");
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let formData = new FormData();

    fileList.forEach((file) => {
      if (file.uid !== "not_new") {
        formData.append("file", file);
      }
    });

    formData.append("data", JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.sponsor.create}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((resData) => {
        close();
        setFilePreview("");
        setFileList([]);
        setIsButtonLoading(false);
        form.resetFields();
        message.success("Patrocinador foi adicionado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  function handleWarningMissingFields() {
    message.error("Faltam alguns campos para preencher!");
  }

  async function handlePreview(file, type) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <Drawer
      width={800}
      title={`Adicionar patrocinador`}
      open={open}
      onClose={close}
      maskClosable={false}
      extra={[
        <Button className="mr-2" size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
          Adicionar
        </Button>,
        <Button size="large" onClick={close}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} id="create-sponsor" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <Form.Item name="type" label="Tipo" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Select
            size="large"
            placeholder="Tipo de patrocinador..."
            options={[
              { id: "Científico", name: "Científico" },
              { id: "Patrocinador", name: "Patrocinador" },
            ].map((value, index) => ({
              value: value.id,
              label: value.name,
            }))}
          ></Select>
        </Form.Item>
        <Form.Item name="img" label="Imagem" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Dragger {...uploadProps} className={`dragger ${filePreview ? "has-preview" : ""}`}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <div
              className={`preview-file ${filePreview ? "has-preview" : ""}`}
              style={{
                backgroundImage: `url(${filePreview && filePreview.includes("base64") ? filePreview : `${config.server_ip}media/${filePreview}`})`,
              }}
            ></div>
          </Dragger>
        </Form.Item>
        <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Input size="large" placeholder="Nome..." />
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
          {({ getFieldValue }) => (
            <Form.Item name="id_tier" label="Tipo" rules={[{ required: getFieldValue("type") === "Patrocinador", message: "Este é um campo obrigatório" }]}>
              <Select
                size="large"
                placeholder="Tier de patrocinador..."
                options={sponsorsTiers.map((value, index) => ({
                  value: value.id,
                  label: value.name,
                }))}
              ></Select>
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item name="pos" label="Posição (nº)" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <InputNumber size="large" min={1} placeholder="1" />
        </Form.Item>
        <Form.Item name="link" label="Link">
          <Input size="large" placeholder="Link..." />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
