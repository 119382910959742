import { Row, Col, Modal, Button, Input, Form, notification, DatePicker, message } from "antd";
import { useState } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";

import config from "../../utils/config.js";
import endpoints from "../../utils/endpoints.js";
import dayjs from "dayjs";

export default function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(values) {
    setIsButtonLoading(true);
    values.date_time = values.date_time ? dayjs(values.date_time).format("YYYY-MM-DD HH:mm:ss") : null;
    axios
      .post(endpoints.notification.create, {
        data: values,
      })
      .then((resData) => {
        close();
        setIsButtonLoading(false);
        form.resetFields();
        message.success("Notificação foi adicionada com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  function handleWarningMissingFields() {
    message.error("Faltam alguns campos para preencher!");
  }

  function handleCloseModal() {
    close();
    form.resetFields();
  }

  return (
    <Modal className="modal-antd-notification" title={`Adicionar notificação`} open={open} onCancel={handleCloseModal} footer={[]}>
      <Form form={form} id="create-notification" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <Form.Item name="title" label="Título" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Input size="large" placeholder="Título..." />
        </Form.Item>
        <Form.Item name="description" label="Descrição" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Input size="large" placeholder="Descrição..." />
        </Form.Item>
        <Form.Item name="date_time" label="Data">
          <DatePicker size="large" className="w-full" showTime />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.date_time !== currentValues.date_time}>
          {({ getFieldValue }) => (
            <div className="flex justify-end items-center">
              <Button className="mr-2" size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={!getFieldValue("date_time")}>
                Agendar
              </Button>
              <Button className="mr-2" size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={getFieldValue("date_time")}>
                Enviar
              </Button>
              <Button disabled={isButtonLoading} size="large" onClick={handleCloseModal}>
                Cancelar
              </Button>
            </div>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}
