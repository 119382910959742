import React, { useState } from "react";
import { Button, Form, Drawer, message, Input, Row, Col } from "antd";
import axios from "axios";

import endpoints from "../../utils/endpoints";

function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [form] = Form.useForm();

  function handleClose() {
    form.resetFields();
    setIsButtonLoading(false);
    close();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios
      .post(endpoints.auth.register, {
        data: values,
      })
      .then((res) => {
        console.log(res);
        if (res.data.registered) {
          setIsButtonLoading(false);
          handleClose();
          form.resetFields();
        } else {
          console.log(res);
          message.error({ content: res.data.message });
          setIsButtonLoading(false);
        }
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-user`}
      title="Criar utilizador"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[
        <Button size="large" disabled={isButtonLoading} className="mr-2" onClick={handleClose}>
          Cancelar
        </Button>,
        <Button size="large" loading={isButtonLoading} type="primary" onClick={form.submit}>
          Adicionar
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Nome" size="large" />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Nome" size="large" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input.Password placeholder="Password" size="large" />
        </Form.Item>

        <Form.Item label="Profissão" name="job_title">
          <Input placeholder="Profissão" size="large" />
        </Form.Item>
        <Form.Item label="Instituição" name="institution">
          <Input placeholder="Instituição" size="large" />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Create;
