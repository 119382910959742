import React, { useState } from "react";
import { Button, Col, Row, Select, Upload, Input, Modal } from "antd";
import axios from "axios";

import endpoints from "../../utils/endpoints";
import { DeleteOutlined } from "@ant-design/icons";

function Update({ open, close, data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleClose() {
    close();
  }

  function handleDelete() {
    setIsButtonLoading(true);
    axios
      .post(endpoints.user.delete, {
        data: data.id,
      })
      .then((res) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Modal
      title={`Apagar utilizador`}
      key={`modal-delete-guide-${data.id}`}
      width={800}
      onCancel={handleClose}
      open={open}
      footer={[
        <Button danger icon={<DeleteOutlined />} size="large" type="primary" onClick={handleDelete} loading={isButtonLoading} disabled={isButtonLoading}>
          Apagar
        </Button>,
        <Button size="large" onClick={close}>
          Cancelar
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <p>Tem a certeza que deseja apagar o seguinte utilizador:</p>
          <p className="text-lg mt-4 mb-2">
            <b>Nome</b>: {data.name}
          </p>
          <p className="text-lg mt-0">
            <b>E-mail</b>: {data.email}
          </p>
        </Col>
      </Row>
    </Modal>
  );
}

export default Update;
