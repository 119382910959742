import { Row, Col, Modal, Button, Input, Form, notification, message } from "antd";
import { useState } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import config from "../../utils/config.js";
import endpoints from "../../utils/endpoints.js";

export default function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.sponsorType.create, {
        data: values,
      })
      .then((resData) => {
        close();
        setIsButtonLoading(false);
        form.resetFields();
        message.success("Tipo de comissão foi adicionado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  function handleWarningMissingFields() {
    message.error("Faltam alguns campos para preencher!");
  }

  function handleCloseModal() {
    close();
    form.resetFields();
  }

  return (
    <Modal
      className="modal-antd-sponsor-type"
      title={`Adicionar tipo de comissão`}
      open={open}
      onCancel={handleCloseModal}
      footer={[
        <Button className="mr-20" size="large" type="primary" onClick={form.submit} loading={isButtonLoading}>
          Adicionar
        </Button>,
        <Button disabled={isButtonLoading} size="large" onClick={handleCloseModal}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} id="add-sponsor-type-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <div className="modal-commission_type">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <div className="left-div">
                <div className="input-div mt-0">
                  <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
                    <Input size="large" placeholder="Nome..." />
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
}
