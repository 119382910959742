import { Row, Col, Divider, Tabs, Form, notification, Switch, Spin, message } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";

import SettingsVersion from "./Settings/SettingsVersion.js";
import SettingsDays from "./Settings/SettingsDays.js";
import SettingsSponsor from "./Settings/SettingsSponsor.js";
import SettingsCountdown from "./Settings/SettingsCountdown.js";
import SettingsPosters from "./Settings/SettingsPosters.js";

import "react-quill/dist/quill.snow.css";

import endpoints from "../utils/endpoints.js";

function Settings() {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.settings.read)
      .then((res) => {
        setData(res.data);
        let settingsDataProfileAccess = res.data.filter((item) => item.name === "private_access")[0];
        form.setFieldsValue(JSON.parse(settingsDataProfileAccess.json));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleSubmit(values) {
    setIsLoading(true);
    setIsButtonLoading(true);

    axios
      .post(endpoints.settings.update, {
        data: { values: values, name: "private_access" },
      })
      .then((res) => {
        setIsButtonLoading(false);
        setIsLoading(false);
        message.success(values.private_access ? "A aplicação já tem acesso ao perfil" : "A aplicação já não tem acesso ao perfil");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsButtonLoading(false);
        message.error("Algo de errado aconteceu! Tente novamente mais tarde.");
      });
  }

  return (
    <div className="dashboard-page">
      <Row className="dashboard-title-row">
        <Col span={12} className="flex justify-start items-center">
          <h3 className="dashboard-title">Definições</h3>
        </Col>
        <Col span={12} className="flex justify-end items-center">
          <Form form={form} className="mr-2" id="add-commission_type-form" layout="horizontal" onFinish={handleSubmit}>
            <Form.Item className="mr-2 mb-0" name="private_access" label="Acesso privado" valuePropName="checked">
              <Switch disabled={isButtonLoading} onChange={form.submit} size="large" />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard-content-row">
          <Col span={24}>
            <Tabs
              type="card"
              items={[
                {
                  label: `Versão`,
                  key: "1",
                  children: <SettingsVersion data={data} />,
                },
                {
                  label: `Dias`,
                  key: "2",
                  children: <SettingsDays data={data} />,
                },
                {
                  label: `Sponsor`,
                  key: "3",
                  children: <SettingsSponsor data={data} />,
                },
                {
                  label: `Countdown`,
                  key: "4",
                  children: <SettingsCountdown data={data} />,
                },
                {
                  label: `Posters`,
                  key: "5",
                  children: <SettingsPosters data={data} />,
                },
              ]}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Settings;
